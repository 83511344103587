@import "theme_variables.scss";
@import "_variables.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "brand.scss";

#mc-header-main {
    max-width: 1700px;
}

#mc-header-full {
    background-color: white;
}

#mc-footer-full {
    background-image: $footer-image;
    background-repeat: repeat-y;
    background-size: auto;
    background-attachment: scroll;
    background-position: center top;
}

.bg-white#sticky-header-container {
    background-color: unset!important;
}

#mc-header-full.scroll-nav{
    .mc-brand-img {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}
.mc-brand-img {
    padding-top: 10px;
    padding-bottom: 10px;
    height: calc($mc-header-height - 20px);
}

/* They use blocky images on a white background, we want to align the content
   with the navbar so no padding*/
#mc-main-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0!important;

}

.search-box form {
    width: 200px;
    input {
        border-radius: 0;
    }
    .inline-search-btn {
    border: 0;
        background: none;
        width: 36px;
        margin-left: -36px;
        color: #aaa;
    }
}

.btn {
    border-radius: 0.2rem;
}

.btn-primary {
    color: white;
    border-radius: 0.2rem;
    &:active,
    &:hover{
        color: white;
        background-color: darken($brand-primary, 10%);
    }
}

.product-add-btns {
    border-radius: 0.2rem;
    border-color: #ccc;
    font-size: 16px;
    color: $black;
    background-color: $white;
}
.product-add-btns:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

#product-images-large > li, #product-images-thumb > li {
    padding: 5px;
    border: 1px solid #eee;
  }



.cart-button {
    background-color: none;
    background: none;
    color: unset;
    border: 0;
    .cart-subtotal-or-count.badge {
        background-color: $gold;
    }

    &:hover {
        background-color: $gold;
    }
}

#mc-header-nav-bar {
    .nav-link {
        text-transform: uppercase;
        font-size: 15px;
    }
}

#compare-nav-btn {
    margin-top: -1px;
    &:hover {
        background-color: unset;
        color: $gold;
    }
}

#mc-main-content-full {
    margin-top: 160px;
}


.input_id_payment_option {
    display: none;
}

.offcanvas-navmenu {
    --bs-nav-link-font-size: 18px;
    .nav-item.menu-item{
        border-bottom: 1px solid var(--bs-gray-300);
    }

}


.btn-secondary {
    color: #333;
    background: #fff;
    border-color: #ccc;
    border-radius: .2rem;
    &:hover {
        color: #333;
        background: #{shade-color(#fff, 10%)};
        border-color: #{shade-color(#ccc, 10%)};
    }
    &:active {
        color: var(--bs-btn-hover-color);
        background: #{shade-color(#fff, 20%)};
        border-color: #{shade-color(#ccc, 20%)};
    }
  }


/* Its always just 'ea' with them */
  .product-thumbnail-unit {
    display: none;
  }

  .breadcrumb {
    --bs-text-opacity: 1;
    color: black !important;
    font-size: smaller;
  }

#mc-main-content-full {
    a:not(.btn):not(.nav-link) {
        text-decoration: underline;
        color: $gold;
    }
    h3 a,
    #recent-posts-accordion a,
    #cat-accordion a,
    #tag-accordion a,
    .black-links a,
    .product-thumbnail-title a,
    .facet-header,
    .mc-edit_order-item-title a {
        text-decoration: unset!important;
        color: unset!important;
    }
}


.owl-carousel .product-thumbnail {
    aspect-ratio: 4/5;
    min-height: 450px;

    @include media-breakpoint-down(xl) {
        .product-thumbnail-name {
            font-size: 0.9em;
        }
        .product-thumbnail-description {
            font-size: 0.8em;
        }
        .product-thumbnail-price {
            font-size: 0.8em;
        }
    }

    .product-thumbnail-overlay,
    .product-thumbnail-hover {
        display: none!important;
    }

    .product-thumbnail-image {
        position: absolute;
        top: 0;
        bottom: 40%;
        left: 0;
        right: 0;
        height: auto;
        img {
            object-fit: contain;
            position: absolute;
            top: 0;
            bottom: 40%;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .product-thumbnail-name {
        position: absolute;
        top: 60%;
        bottom: 25%;
        left: 10%;
        right: 10%;
        padding: 5px;
        color: $black;
        text-align: center;
        a {
            font-size: 1.8em;
            font-weight: 400;
        }
    }

    .product-thumbnail-description {
        position: absolute;
        top: 72%;
        bottom: 15%;
        left: 0;
        right: 0;
        font-family: serif;
        font-size: 1.6em;
        color: $gold;
    }

    .product-thumbnail-price {
        position: absolute;
        top: 85%;
        bottom: 95%;
        left: 0;
        right: 0;
        padding: 5px;
        text-align: center;
        font-size: 1.5em;
    }

}

.font-serif {
    font-family: 'Ibarra Real Nova', serif;
}


.header-link.show {
    color: var(--bs-primary);
}

#searchBox {
    width: 400px;
}

#mc-header-main > .row {
    background-color: white;
}

#searchBox,
.navbar-right ul.dropdown-menu,
.navbar-left ul.dropdown-menu {
    top: 27px!important;
    border: 0;
    border-radius: 0px 0px 15px 15px;
    border-top: 3px solid white;
    padding-bottom: 0.8rem;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 6px 4px);

    a.dropdown-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

}

@include media-breakpoint-down(xxl) {
    #searchBox,
    .navbar-right ul.dropdown-menu,
    .navbar-left ul.dropdown-menu {
        top: 32px!important;
    }
}

@include media-breakpoint-down(xl) {
    #searchBox,
    .navbar-right ul.dropdown-menu,
    .navbar-left ul.dropdown-menu {
        top: 26px!important;
    }
}

#mc-header-full {
    height: $mc-header-full-height;
}
#mc-header-main,
#mc-header-main-left,
#mc-header {
    height: $mc-header-height;
}

.product-list {
    .product-thumbnail {
        border-radius: 15px;
    }
    .product-thumbnail-name {
        a {
            font-size: 0.9rem;
            line-height: 1.2;
            font-weight: 800;
        }
    }
    
    .product-thumbnail-description {
        flex-grow: 0;
        margin-bottom: 10px;
        strong small {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }
    
    .prd-thumb-variation, .product-thumbnail-current {
        font-weight: 400;
        font-size: 14px;
    }
    
    .product-add-btns {
        border-radius: 8px;
        &:hover {
            background-color: #f9f1e3;
        }
    }
    .product-add-to-list {
        display: none;
    }
    .btn-favourite-product {
        background: transparent !important;
        border: none !important;
    }
    .product-thumbnail-image {
        margin-bottom: 5px;
    }
}
