@import "fonts.scss";

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

// Brand Colors
$black: #000;
$white: #fff;
$whitesmoke: #f5f5f5;
$gold-light: #bdad8b;

$black: #151515;
$soot: #202020;
$white: #fff;
$steam: #ddd;
$gold: #ab8d50;
$shine: #eabf6d;
$beige: #ebdabe;

// Bootstrap Overrides
$link-color:            --var(bs-gray-800);
$link-hover-color:      $gold;

$brand-font: "Lato", georgia, serif;

$brand-primary: $gold;
$brand-secondary: white;
$primary: $brand-primary;
$secondary: $brand-secondary;


// Theme Overrides
$navbrand-logo-height: 180px;
$navbrand-logo-height-small: 120px;

$mc-header-height: 90px;
$mc-header-height-scroll: 90px;

$mc-header-nav-bg: #333;
$mc-header-nav-highlight: $brand-primary;
$mc-header-nav-height: 0px;

$header-wrapper-image: url(../../img/murchies-tea-coffee-header-footer-white_1.png);
$footer-image: url(../../img/murchies-tea-coffee-header-footer.png);


$product-thumbnail-price-size: 14px;