@font-face {
    font-family: 'Ibarra Real Nova';
    src: url('../../fonts/Ibarra_Real_Nova/IbarraRealNova-VariableFont_wght.ttf') format('truetype');
    font-weight: 125 950;
    font-style: normal;
}

@font-face {
    font-family: 'Ibarra Real Nova';
    src: url('../../fonts/Ibarra_Real_Nova/IbarraRealNova-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 125 950;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}